<template>
  <top-header-menu-wrapper>
    <template v-slot:left>
      <home-button />
    </template>
    <div class="component-title">
      Groups
    </div>
    <template v-slot:right>
      <button class="add-space icon-hea1">
        <icon icon="#cx-hea1-add" />
      </button>
    </template>
  </top-header-menu-wrapper>
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
export default {
  name: "GroupsHeaderMenu",
  components: {
    TopHeaderMenuWrapper
  }
};
</script>
