<template>
  <mobile-screen :header="true" screen-class="groups-screen">
    <template v-slot:header>
      <groups-header-menu />
      <section class="search-wrapper groups-search">
        <div class="search-field">
          <input type="text" placeholder="Search" />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>
    <ul class="items-list">
      <li class="list-item">
        Group
      </li>
      <li class="list-item">
        Group
      </li>
      <li class="list-item">
        Group
      </li>
    </ul>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import GroupsHeaderMenu from "@/components/groups/GroupsHeaderMenu";
export default {
  name: "Groups",
  components: {
    MobileScreen,
    GroupsHeaderMenu
  }
};
</script>
